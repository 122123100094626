<template>
    <BT-Blade-Item
      bladeName="dispatch-pickup"
      :canDelete="false"
      :loadingMsg="loadingMsg"
      navigation="releases"
      :onPullSuccessAsync="pullConsignments"
      title="Pickup">
      <template v-slot="{ item }">
        <BT-Snack v-model="msg" />
  
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>Name</v-list-item-subtitle>
            <v-list-item-title>{{ item.bundleName || item.destinationLocation.companyAccount.companyName }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
  
        <BT-Field-Date
          v-model="item.dueDepartureOn"
          label="Due Date"
          shortDateAndTime />

      </template>
    </BT-Blade-Item>
  </template>
  
  <script>
  export default {
    name: 'Dispatch-Release',
    components: {
      
    },
    data: function() {
      return {
        consignment: null,
        loadingMsg: null,
        msg: null
      }
    },
    methods: {
      async pullConsignments(release) {
          var consignmentIDs = await this.$BlitzIt.api.get('releases', '/get/GetConsignmentIDs/' + release.id, null, null);

          if (this.isArrayOfLength(consignmentIDs, 1)) {
            try {
              this.consignment = await this.$BlitzIt.store.get('stock-consignments', consignmentIDs[0], null, true, null, null);
            }
            catch (err) {
              this.msg = this.extractErrorDescription(err);
            }
            finally {
              this.loadingMsg = null;
            }
          }
          else {
            this.msg = 'Can only release one consignment at a time';
          }

          return release;
      },
      async saveRelease(release, proofOfRelease) {
        if (proofOfRelease == null) {
          this.msg = 'No signature provided';
          return;
        }

        try {
          this.msg = 'Saving Release';
          release.proofOfRelease = proofOfRelease;
          var res = await this.$BlitzIt.store.patch('releases', release);
          release.rowVersion = res.rowVersion;
          release.releasedOn = res.releasedOn;
          release.releasedByUserID = res.releasedByUserID;
          this.changeToggle = !this.changeToggle;
        }
        catch (err) {
          this.msg = this.extractErrorDescription(err);
        }
        finally {
          this.loadingMsg = null;
        }
      },
    }
  }
</script>